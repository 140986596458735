
import dolar from '../../image/tarifs/dolar.webp';
import './Question.scss';

const Question = () => {
  return (
    <section className="question">
      <div className="question__container">
        <img src={dolar} alt="dolar" className='question__dolar' />
        <div className="question__contant">
          <span className='question__big'>Залишилися питання по навчанню?</span>
          <span className='question__small'>Ми НАдамо відповідь!</span>
        </div>
        <a href='https://t.me/banit_support' target="_blank" rel="noopener noreferrer" className='question__button'>Задати питання</a>
      </div>
    </section>
  )
}

export default Question;