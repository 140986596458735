

import insta from '../../image/footer/insta.webp';
import telegram from '../../image/footer/telegram.webp';
import youtube from '../../image/footer/youtube.webp';
import bg from '../../image/footer/footer_bg.webp';
import light from '../../image/main/blick-center.png';

// import validationForm from '../../utils/validation';

// import arrow from '../../image/icons/step_arrow.webp';



import './Footer.scss';


const Footer = () => {

  return (
    <footer className='footer '>

      <img src={bg} alt="bg"  className='footer__bg'/>
      <img src={light} alt="bg"  className='footer__light'/>

      <div className="footer__container">
        <div className="footer__left">
          <div className='footer__content'>
            <span>ФОП Баніт Юлія Олександрівна</span>
            <span>IBAN UA753220010000026009330132989</span>
            <span>ІПН 3637305265</span>
          </div>
          <p className='footer__text'>
            Я не фінансовий, податковий, юридичний або радник, що ліцензується. Весь курс не має розглядатися як фінансова, податкова, юридична порада
          </p>
        </div>

        <div className="footer__right">
          <div className="footer__social">
            <a href="https://instagram.com/yulia_banit?igshid=OGQ5ZDc2ODk2ZA==" target="_blank" rel="noopener noreferrer">
              <img src={insta} alt="insta" />
            </a>
            <a href="https://t.me/banit_support" target="_blank" rel="noopener noreferrer">
              <img src={telegram} alt="telegram" />
            </a>
            <a href="https://youtube.com/@BanitYulia?si=05samznmvpgTjOKM" target="_blank" rel="noopener noreferrer">
              <img src={youtube} alt="youtube" />
            </a>
          </div>
          <a className='footer__link' href="https://drive.google.com/file/d/1CCNflaTtjok5YehoYGV61b9EWWgLczDQ/view?usp=drivesdk" target="_blank" rel="noopener noreferrer">Політика конфіденційності</a>
          <a className='footer__link' href="https://drive.google.com/file/d/1DDDJLKHI_Oo1eSiSctKWJvpBa7AVzYAM/view?usp=drivesdk" target="_blank" rel="noopener noreferrer">Публічний договір (Оферта)</a>
        </div>
      </div>

      <a href="https://newstart-digital.com.ua/" className='footer__create' target="_blank" rel="noopener noreferrer">Created by New Start Digital</a>
    </footer>
  )
}

export default Footer;