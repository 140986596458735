import React from 'react';
import ReactDOM from 'react-dom/client';

import App from './components/App/App';
import {
  BrowserRouter
} from "react-router-dom";


import './reset.scss';
import './base.scss';
import './index.scss';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <App />
    </BrowserRouter>
    
  </React.StrictMode>
);

