import mentor from '../../image/mentor/mentor_mentor.webp';
import background from '../../image/main/main-background.webp';
import dolar from '../../image/tarifs/dolar.webp';
import light from '../../image/tarifs/tarifs-right-light.webp';

import './Mentor.scss';

const Mentor = () => {
  const dot = 
    <svg xmlns="http://www.w3.org/2000/svg" width="19" height="19" viewBox="0 0 19 19" fill="none">
      <circle cx="9.5" cy="9.5" r="9" fill="#27220F" stroke="url(#paint0_linear_260_1312)"/>
      <defs>
        <linearGradient id="paint0_linear_260_1312" x1="-1.23131e-07" y1="16.5" x2="19" y2="-3" gradientUnits="userSpaceOnUse">
          <stop stopColor="#FFA719"/>
          <stop offset="1" stopColor="#FFA719" stopOpacity="0"/>
        </linearGradient>
      </defs>
    </svg>

  return (
    <section className='mentor'>

      <img src={background} alt="background" className='mentor__bg'/>
      <div className="mentor__blur-top"></div>
      <img src={dolar} alt="dolar" className='mentor__dolar'/>
      <div className="mentor__blur-bottom"></div>

      <div className="mentor__blur-bottom-bg"></div>


      <div className="mentor__container">

        <div className="mentor__desctop desctop">
          <div className="desctop__header">
            <h3>Гроші - це здоровий глузд, а потім цифри та букви</h3>
          </div>

          <div className="desctop__content">
            <div className="desctop__left">
              <h3>Юлія</h3>

              <ul className="desctop__list">
                <li className="desctop__item">{dot}Експерт з фінансової грамотності та інвестицій </li>
                <li className="desctop__item">{dot}Практикуючий міжнародний інвестор</li>
                <li className="desctop__item">{dot}Бізнес-тренер і коуч за стандартами ICF</li>
              </ul>
            </div>

            <img src={mentor} alt="mentor" className='mentor__photo'/>

            <div className="desctop__right">
              <h3>Баніт</h3>

              <ul className="desctop__list">
                <li className="desctop__item">{dot}<span>4+</span> роки в інвестиціях </li>
                <li className="desctop__item">{dot}<span>x24</span> рази ріст вартості інвестиції в сфері нерухомості</li>
                <li className="desctop__item">{dot}<span>100+</span> проведених консультацій</li>
              </ul>
            </div>
          </div>

        </div>

        <div className="mentor__mobile mobile">
  
            <div className="mobile__header">
              <h3>Гроші - це здоровий глузд, а потім цифри та букви</h3>
            </div>

            <div className="mobile__content">
              <div className="mobile__mentor-photo">
                <img src={mentor} alt="mentor" className='mentor__photo'/>
                <div className="mentor__blur-photo"></div>
                <img src={light} alt="light"  className='mentor__photo-light'/>
              </div>
              {/* <div className="mobile__left"> */}
                <h3 className='mobile__name'>
                  Юлія 
                  <img src={dolar} alt="dolar" className='mentor__dolar'/>
                  <span>Баніт</span>
                </h3>

                <ul className="mobile__list">
                  <li className="mobile__item">{dot}Експерт з фінансової грамотності та інвестицій </li>
                  <li className="mobile__item">{dot}Практикуючий міжнародний інвестор</li>
                  <li className="mobile__item">{dot}Бізнес-тренер і коуч за стандартами ICF</li>
                  <li className="mobile__item">{dot}<span>4+</span> роки в інвестиціях </li>
                  <li className="mobile__item">{dot}<span>x24</span> рази ріст вартості інвестиції в сфері нерухомості</li>
                  <li className="mobile__item">{dot}<span>100+</span> проведених консультацій</li>
                </ul>
              {/* </div> */}

              

            </div>

        </div>

      </div>

    </section>
  )
}

export default Mentor;