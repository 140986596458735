import { useEffect, useState } from "react";

import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper-bundle.css';

import Header from "../../components/Header/Header";
import Main from "../../components/Main/Main";
import ForWhom from "../../components/ForWhom/ForWhom";
import Program from "../../components/Program/Program";
import Tariffs from "../../components/Tariffs/Tariffs";
import Mentor from "../../components/Mentor/Mentor";
import PrivateAdvising from "../../components/PrivateAdvising/PrivateAdvising";
import PencionAdvising from "../../components/PencionAdvising/PencionAdvising";
import Review from "../../components/Rewiev/Review";
import Faq from "../../components/Faq/Faq";
import Question from "../../components/Question/Question";
import Footer from "../../components/Footer/Footer";
import { Helmet } from "react-helmet";

const NoscriptTag = () => (
  <noscript>
    <img
      height="1"
      width="1"
      style={{ display: 'none' }}
      src="https://www.facebook.com/tr?id=233624746443522&ev=PageView&noscript=1"
      alt="pixel"
    />
  </noscript>
);

const MainPage = () => {

  return (
    <div className="app">
      {/* <Helmet defer={false}> */}
        {/* <meta name="facebook-domain-verification" content="5kyw0jw17ndg0khltu1lar0aazaix3" />
        <script type="text/javascript">
          {`
            !function(f,b,e,v,n,t,s){
              if(f.fbq)return;n=f.fbq=function(){n.callMethod?
              n.callMethod.apply(n,arguments):n.queue.push(arguments)};
              if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
              n.queue=[];t=b.createElement(e);t.async=!0;
              t.src=v;s=b.getElementsByTagName(e)[0];
              s.parentNode.insertBefore(t,s)
            }(window, document, 'script', 'https://connect.facebook.net/en_US/fbevents.js');
            fbq('init', '233624746443522');
            fbq('track', 'PageView');
            fbq('track', 'Lead');
            fbq('track', 'Purchase', {value: 0.00, currency: 'USD'});
          `}
        </script>
        <noscript>
          {`<img
            height="1"
            width="1"
            style={{ display: 'none' }}
            src="https://www.facebook.com/tr?id=233624746443522&ev=PageView&noscript=1"
            alt="pixel"
          />`}
        </noscript> */}
        {/* <noscript dangerouslySetInnerHTML={{ __html: noscriptContent }} /> */}
      {/* </Helmet> */}

      <div className="app__container" style={{overflow: 'hidden'}}>
        <Header />
        <Main />
        <a id="about"></a>
        <ForWhom/>
        <a id="program"></a>
        <Program/>
        <a id="tariffs"></a>
        <Tariffs/>
        <Mentor/>
        <PrivateAdvising/>
        <PencionAdvising/>
        <a id="reviews"></a>
        <Review/>
        <Faq/>
        <Question/>
        <a id="contact"></a>
        <Footer/>
      </div>
    </div>
  )
}

export default MainPage;