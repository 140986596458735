import money from '../../image/main/money-round.webp';
import light from '../../image/tarifs/tarifs-right-light.webp';
import crown from '../../image/tarifs/crown.webp';
import dolar from '../../image/tarifs/dolar.webp';


import './Tariffs.scss';

const Tariffs = () => {
  const dot = <svg xmlns="http://www.w3.org/2000/svg" width="19" height="19" viewBox="0 0 19 19" fill="none">
                <circle cx="9.5" cy="9.5" r="9" fill="#27220F" stroke="url(#paint0_linear_260_1277)"/>
                <defs>
                  <linearGradient id="paint0_linear_260_1277" x1="-1.23131e-07" y1="16.5" x2="19" y2="-3" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#FFA719"/>
                    <stop offset="1" stopColor="#FFA719" stopOpacity="0"/>
                  </linearGradient>
                </defs>
              </svg>

  return (
    <section className='tariffs'>

      <div className="tariffs__bg">
        <img src={light} alt="light" className='tariffs__left' />
        
        <img src={light} alt="light" className='tariffs__light' />
      </div>

      <img src={money} alt="money" className='tariffs__money' />

      <h2 className="tariffs__title title-h2">тарифи</h2>
      <div className="tariffs__container">
        

        <div className="tariffs__tariff tariff__bace">
          <div className="tariff__header">
            <div className="tariff__border">
              <img src={dolar} alt="dolar" className='tariff__dolar'/>
              <h3 className='tariff__title'>Базовий</h3>
              <span className='tariff__old-price'>2300 грн</span>
              <span className='tariff__new-price'>1290 грн</span>
            </div>
          </div>

          <div className="tariff__center">
            <div className="tariff__border">
              <div className='tariff__service'>{dot} <span>1-4 модуль без додаткових уроків</span></div>
              <div className='tariff__service'>{dot} <span>загальний чат</span></div>
            </div>
          </div>

          <a href='https://secure.wayforpay.com/button/b5da650bcae17' target="_blank" rel="noopener noreferrer" className="tariffs__button button">
            <span>купити</span>
          </a>
        </div>

        <div className="tariffs__tariff tariff__pro">
          <div className="tariff__header">
            <div className="tariff__border">
              <img src={crown} alt="crown" className='tariff__crown' />
              <h3 className='tariff__title'>Хочу більше</h3>
              <span className='tariff__old-price'>3990 грн</span>
              <span className='tariff__new-price'>2990 грн</span>
            </div>
          </div>

          <div className="tariff__center">
            <div className="tariff__border">
              <div className='tariff__service'>{dot} <span>1-4 модуль з додатковими уроками</span></div>
              <div className='tariff__service'>{dot} <span>загальний чат</span></div>
              <div className='tariff__service'>{dot} <span>доступ в закрите фінансове ком’юніті з регулярними zoom-зустрічами</span></div>
            </div>
          </div>

          <a href='https://secure.wayforpay.com/button/bd33cc5b29eb2' target="_blank" rel="noopener noreferrer" className="tariffs__button button">
            <span>купити</span>
          </a>
        </div>
      </div>
    </section>
  )
}

export default Tariffs;