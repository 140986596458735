
import mentor from '../../image/main/mentor.webp';
import money from '../../image/advising/advising-money.webp';
import light from '../../image/program/program__light.webp';

import './PrivateAdvising.scss';

const PrivateAdvising = () => {
  return (
    <section className='advising'>
      <img src={money} alt="money" className='advising__money'/>
      <img src={light} alt="money" className='advising__light'/>

      <div className="advising__container">
        <div className="advising__photo">
          <img src={mentor} alt="mentor" className='advising__mentor' />
          <div className="advising__photo-blur"></div>
        </div>

        <div className="advising__info info">
          <h3 className='info__title'>
            Особиста консультація 
            <span>з Юлією Баніт</span>
          </h3>

          <ul className="info__list">
            <li className="info__item">Форма проведення: онлайн</li>
            <li className="info__item">Перед консультацією вам потрібно заповнити форму, щоб я наперед виконала прорахунки та краще дізналась ваш запит</li>
            <li className="info__item">Зустріч проходить у форматі розбору вашого запиту на консультацію та відповідей на ваші запитання</li>
          </ul>

          <ul className="info__list-cost">
            <li className="info__item"><div>Тривалість зустрічі <span>1,5 - 2 години</span></div></li>
            <li className="info__item"><div>Вартість <span>200 євро</span></div></li>
          </ul>

          <a href='https://t.me/B_Julia_A' target="_blank" rel="noopener noreferrer" className='info__button button'><span>Особиста консультація зі мною</span></a>
        </div>
      </div>
    </section>
  )
}

export default PrivateAdvising;