import ogai from '../../image/review/ogai.webp';
import liliana from '../../image/review/liliana.webp';
import elizabet from '../../image/review/elizabet.webp';
import burlakNatali from '../../image/review/burlakNatali.webp';
import annaBermas from '../../image/review/annaBermas.webp';
import konovalenco from '../../image/review/konovalenco.webp';


import './Review.scss';

const data = [
  {
    src: ogai,
    description: ['Бажаю успіхів у наступних потоках, бо це крутий курс і люди мають про нього знати і вчитися управляти грошима)'],
    instagram: '@elgelfina',
    name: 'Олена'
  },
  {
    src: liliana,
    description: ['Хочу залишити відгук на курс  стільки нового та структуровано про фінанси я ще не бачила.', 'Найціннішими для мене були уроки по мисленню та фін.грамотності: скільки куди відкладати, про подушку було відкриття, що насправді вона не обов‘язково має просто лежати  весь хаос в фінансових потоках перетворився в зрозумілу систему, а головне я знаю, що саме мені потрібно робити! Дуже вдячна тобі за цей курс та всім буду радити)'],
    instagram: '@lisa_tymtso',
    name: 'Єлизавета'
  },
  {
    src: elizabet,
    description: ['Я проходила курс по финансовой грамотности почти год назад, НО до сих пор пользуюсь материалами с обучения и каждый раз нахожу в них что-то новое для себя! Для меня тема денег и всего, что связано с числами - всегда была темным лесом, так как я любитель жить интуитивно. Но после курса Юли случилось чудо, я стала понимать, КАК взаимодействовать с финансами ПРАВИЛЬНО!', 'Пы.сы. Мой доход стал больше, спасибо, Юля'],
    instagram: '@lanowww',
    name: 'Ліліана'
  },
  {
    src: burlakNatali,
    description: ['Хочу написати про фінансові зміни які змінили моє життя. За допомогою цих знань я змогла швидко віддати борг, заплатити мамі за зуби (майже така сама сума як борг), назбирати фін подушку безпеки і при цьому жити нормально дозволяючи собі комфортне життя а не від копійки до копійки як то кажуть. Ця частина курсу (знання) допомогли мені це зробити і я дуже вдячна за це  Я не вірила що получиться так багато досягти за короткий проміжок часу   Це важливі знання, які потрібні кожному хто хоче досягати фінансових ю вершин в своєму житті'],
    instagram: '@Bnk1821',
    name: 'Наталія'
  },
  {
    src: annaBermas,
    description: ['Я дуже тобі вдячна за розбір мого фінансового стану і вдячна за такий курс ти справжній професіонал.', 'Інформація точна, корисна, стисла (без води). Саме то що мені було потрібно'],
    instagram: '@q.annie_b',
    name: 'Анна'
  },
  {
    src: konovalenco,
    description: ['Юля, хочемо з чоловіком  подякувати за курс! Було багато корисної інформації, від впливу ментального/психологічного стану на досягнення фінансових цілей - до використання фін. інструментів/інвестицій.', 'Круто і корисно було дізнатись, які бувають фін. інструменти, для яких цілей, кому підійдуть, як краще розподіляти фінанси і купа всього іншого.'],
    instagram: '@KononenkoCoach',
    name: 'Олена'
  },
  
];

const Review = () => {

  const renderRevies = (data) => {
    return data.map(item => {
      const {src, description, instagram, name} = item;

      return (
        <li className='revies__item item' key={instagram}>
          <p className="item__text">
            <span>{description[0]}</span>
            <span>{description[1]}</span>
          </p>
          <div className="item__container">
            <img src={src} alt={name} />

            <div className="item__info">
              <span className='item__name'>{name}</span>
              <span className='item__insta'>{instagram}</span>
            </div>
          </div>
        </li>
      )
    })
  }

  return (
    <section className='review'>
      <div className="review__container">
        <h2 className='review__title title-h2'>Наші кейси та відгуки:</h2>

        <ul className="review__list">
          {renderRevies(data)}
        </ul>
      </div>
    </section>
  )
}

export default Review;