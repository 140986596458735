
import giftIcon from '../../image/program/gift.webp';
import light from '../../image/program/program__light.webp';

import './Program.scss';

const data = [
  {
    module: 'Мислення достатку',
    // moduleTheame: '',
    lesson: [
      'Працюємо з фінансовим мисленням, обмежуючими твердженнями та закладаємо позитивні установки про гроші',
      'Економія та фінансовий стрес',
      'Працюємо зі страхом грошей'
    ],
    result: [
      'Пропрацюєте власні обмежуючі твердження про гроші',
      'Закладете основи мислення багатої людини',
      'Перестанете боятись грошей та зрозумієте причини свого страху',
      'Зрозумієте, як розширювати власні фінансові можливості'
    ],
    gift: []
  },
  {
    module: 'Основи фінансової грамотності',
    // moduleTheame: '',
    lesson: [
      'Рахуємо вартість робочого часу',
      'Розбираємо формування особистого та родинного бюджету. Як правильно вести облік і заощаджувати гроші',
      'Складаємо фінансовий резерв',
      'Розбираємо як швидко та дешево закрити всі борги',
      'Визначаємо ваш фінансовий імунітет'
    ],
    result: [
      'Розберетесь, як правильно формувати бюджет, розподіляти фінанси у шлюбі та говорити з дітьми про гроші',
      'Визначите розмір фінансового резерву та дізнаєтесь як закрити борги без стресу, швидко та дешево',
      'Наведете порядок у фінансових документах'
    ],
    gift: [
      'Як говорити з дітьми про гроші'
    ]
  },
  {
    module: 'Особистий фінансовий план',
    // moduleTheame: '',
    lesson: [
      'Визначаємо ваш фінансовий рівень',
      'Ставимо фінансову ціль та визначаємо фінансові пріоритети',
      'Формуємо приватну пенсію'
    ],
    result: [
      'Створите готовий план як перейти на наступний фінансовий рівень',
      'Поставите фінансову ціль та створите план пенсійного накопичення',
      'Пропишете чорний сценарій і план Б'
    ],
    gift: [
      'Чек - лист чорного сценарію'
    ]
  },
  {
    module: 'Основи інвестування',
    // moduleTheame: '',
    lesson: [
      'Розбираємось з правилами примноження грошей',
      'Депозити',
      'Державні облігації',
      'Огляд всіх інвестиційних інструментів'
    ],
    result: [
      'Отримаєте навички розпізнавання фінансових пірамід',
      'Визначите безпечні банки для користування та отримаєте знання про державні облігації',
      'Отримаєте повне уявлення про те, як і де примножувати власні накопичення та чим відрізняються дитячі накопичення від дорослих'
    ],
    gift: [
      'Як розпізнати фінансову піраміду',
      'Дитячі накопичення'
    ]
  }
]

const Program = () => {

  const returnModules = (data) => {
    return data.map((item, i) => {
      const {module, lesson, result, gift} = item;
      const lessons = lesson.map((item, i) => {
        return (
          <li className='item__lesson' key={item}>
            <span>Урок {i + 1}</span> - {item}
          </li>
        )
      })

      const gifts = gift.map((item, i) => {
        return (
          <li className='item__gift' key={item}>
            <img src={giftIcon} alt="gift" />
            <div className="">
              <div className='item__add-lesson'>Додатковий урок: </div> 
              {item}
            </div>
          </li>
        )
      })

      const results = result.map((item, i) => {
        return (
          <li className='item__result' key={item}>
            {item}
          </li>
        )
      })

      return (
        <li className='program__item item' key={i}>
          <div className="item__container">
            <div className="item__header">
              <span>{i + 1} модуль:</span>
              <p>{module}</p>
            </div>
            <div className="item__center">
              <ul className="item__lessons">
                {lessons}
              </ul>

              <ul className="item__gifts">
                {gifts}
              </ul>
            </div>

            <div className="item__footer">
              <h3>Результат після проходження модуля:</h3>
              
              <ul className="item__results">
                {results}
              </ul>
            </div>
          </div>
        </li>
      )
    })
  }

  return (
    <section className='program'>

      <div className="program__bg">
        <img src={light} alt="light" className='program__light-left' />
        <img src={light} alt="light" className='program__light-right' />
      </div>
    
      <div className="program__container">
        <div className="program__header">
          <h2 className="program__title title-h2">Програма курсу</h2>
        </div>

        <ul className="program__list">
          {returnModules(data)}
        </ul>

      </div>
    </section>
  )
}

export default Program;