import { useState, useEffect } from 'react';
import { motion, useAnimation } from 'framer-motion';


import mentorPhoto from '../../image/main/mentor.webp';
import bg from '../../image/main/main-background.webp';
import moneyLeft from '../../image/main/money-round.webp';
import moneyRight from '../../image/main/money-round.webp';
import blick from '../../image/main/blick-yellow.webp';
import blickCenter from '../../image/main/blick-center.png';

import './Main.scss';


const Main = () => {

  const controls = useAnimation();
  const [scrollY, setScrollY] = useState(0);

  useEffect(() => {
    const handleScroll = () => {
      setScrollY(window.scrollY);
    };

    controls.start({ opacity: 1, y: 0 }); // Початкове значення анімації

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [controls]);

  return (
    <main className="main">
      <div className="main__background">
        <img src={bg} alt="background" className='main__bg' />

        
        

        <div className="main__blur"></div>
      </div>
      <img src={moneyRight} alt="money right" className='main__money-right'/>
      <img src={moneyLeft} alt="money left" className='main__money-left'/>
        
      <img src={blick} alt="blick" className='main__blick-right'/>

      <img src={blick} alt="blick" className='main__blick-left'/>

      <div className="main__container">
        <div className="main__photo">
          <img src={mentorPhoto} alt="mentor" className='main__mentor'/>
          <img src={blickCenter} alt="mentor" className='main__mentor-blick'/>
          <div className="main__mentor-blur"></div>
        </div>

        <div className="main__info">
          <h1 className='main__title '>Фінансова <span>грамотність</span></h1>
          <p className='main__description'>Перестаньте відчувати постійну тривогу через гроші. Розберіться у власних фінансах раз і назавжди!</p>
        
          <div className="main__buttons">
            <a href='#tariffs' className='main__button button'><span>Придбати курс</span></a>
            <a href='#program' className='main__button button'><span>Переглянути програму</span></a>
          </div>
        </div>
      </div>


    </main>
  )
}

export default Main;