import dolar from '../../image/tarifs/dolar.webp';
import light from '../../image/tarifs/tarifs-right-light.webp';
import gift from '../../image/program/gift.webp';

import './PencionAdvising.scss';

const PencionAdvising = () => {

  const dot = 
    <svg xmlns="http://www.w3.org/2000/svg" width="19" height="19" viewBox="0 0 19 19" fill="none">
      <circle cx="9.5" cy="9.5" r="9" fill="#27220F" stroke="url(#paint0_linear_260_1312)"/>
      <defs>
        <linearGradient id="paint0_linear_260_1312" x1="-1.23131e-07" y1="16.5" x2="19" y2="-3" gradientUnits="userSpaceOnUse">
          <stop stopColor="#FFA719"/>
          <stop offset="1" stopColor="#FFA719" stopOpacity="0"/>
        </linearGradient>
      </defs>
    </svg>

  return (
    <section className='pension'>

      <img src={dolar} alt="dolar" className='pension__dolar' />
      <img src={light} alt="light" className='pension__light' />

      <div className="pension__container">
        <div className="pension__header">
          <img src={gift} alt="gift" className='pension__gift pension__gift-left' />
          <h3 className='pension__title'>Безкоштовна консультація з пенсійних накопичень</h3>
          <img src={gift} alt="gift" className='pension__gift pension__gift-right' />
        </div>

        <ul className="pension__list">
          <li className="pension__item">{dot} Форма консультації: онлайн</li>
          <li className="pension__item">{dot} Тривалість консультації: 30-40 хвилин</li>
          <li className="pension__item">{dot} Отримайте безкоштовну консультацію по пенсійним накопиченням із детальними прорахунками</li>
          <li className="pension__item">{dot} Консультацію проводить експерт із лайфового страхування</li>
        </ul>

        <a href='https://t.me/pensiyni_finans' target="_blank" rel="noopener noreferrer" className='pension__button button'><span>Записатися на <b>безкоштовну</b> консультацію</span></a>
      </div>
    </section>
  )
}

export default PencionAdvising;