import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';

import light from '../../image/pension/pension-light-left.webp';

import './Faq.scss';


const data = [
  {
    question: 'В якому форматі проходить навчання?',
    answer: ['Всі уроки в записі. На тарифі "Хочу більше" є додаткові онлайн-зустрічі з Юлією']
  },
  {
    question: 'Коли починається навчання?',
    answer: ['Доступ до уроків відкривається одразу після оплати. Вам не потрібно чекати старт потоку']
  },
  {
    question: 'Які навички я отримаю?',
    answer: ['- керування особистим і родинним бюджетом', '- керування кредитними коштами', '- комфортне фінансове планування', '- пошук фінансових можливостей']
  },
  {
    question: 'Що робити якщо неправильно ввів/ввела свої дані?',
    answer: ['Напишіть в техпідтримку в телеграм'],
    link: 'https://t.me/banit_support'
  },
  {
    question: 'Скільки часу буде доступ до уроків?',
    answer: ['Доступ до навчальних матеріалів зберігається назавжди'],
  },
  {
    question: 'Чи буде в курсі інформація про пасивний дохід та інвестиції?',
    answer: ['Курс направлений на фундаментальну роботу з власними фінансами. Блок по роботі з інвестиціями буде'],
  },
]

const Faq = () => {

  const customClassesAccordion = {
    root: 'faq__accordion',
    rounded: 'custom-rounded-class',
    expanded: 'custom-expanded-class',
    disabled: 'custom-disabled-class',
    region: 'custom-region-class',
  };

  const customClassesDetails = {
    root: 'faq__details',
    rounded: 'custom-rounded-class',
    expanded: 'custom-expanded-class',
    disabled: 'custom-disabled-class',
    region: 'custom-region-class',
  };

 

  const customClassesSummary = {
    root: 'faq__summary',
    rounded: 'custom-rounded-class',
    expanded: 'custom-expanded-class',
    disabled: 'custom-disabled-class',
    region: 'custom-region-class',
    
  }

  const renderAccordion = (data) => {
    return data.map((item, i) => {
      const {question, answer} = item;
      const answers = answer.map(item => {
        return <span key={item}>{item}</span>
      })

      return (
          <Accordion  
            key={i}
            classes={customClassesAccordion}
            >
            <AccordionSummary
              classes={customClassesSummary}
              expandIcon={
                <svg xmlns="http://www.w3.org/2000/svg" width="26" height="23" viewBox="0 0 26 23" fill="none">
                  <path d="M13 23L0.00961882 0.499999L25.9904 0.500002L13 23Z" fill="white"/>
                </svg>
              }            
              aria-controls="panel1a-content"
              id="panel1a-header"

            >
              <svg xmlns="http://www.w3.org/2000/svg" width="19" height="19" viewBox="0 0 19 19" fill="none">
                <circle cx="9.5" cy="9.5" r="9" fill="#27220F" stroke="url(#paint0_linear_260_10142)"/>
                <defs>
                  <linearGradient id="paint0_linear_260_10142" x1="-1.23131e-07" y1="16.5" x2="19" y2="-3" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#FFA719"/>
                    <stop offset="1" stopColor="#FFA719" stopOpacity="0"/>
                  </linearGradient>
                </defs>
              </svg>
              <span className='accourdian__text'>{question}</span>
              
            </AccordionSummary>
            {
              i !== 3 ?
                <AccordionDetails
                  classes={customClassesDetails}
                >
                  {answers}
                </AccordionDetails>
              :
              <AccordionDetails
                  classes={customClassesDetails}
              >
                <div>{answers} <a className='accourdian__support-link' target="_blank" rel="noopener noreferrer" href={item.link}>{item.link}</a></div>
              </AccordionDetails>
            }
          </Accordion>
      )
    })
  }

  return (      
    <section className="faq ">
      <img src={light} alt="light"  className='faq__light'/>
      
      {/* <div className="faq__bg1"></div>
      <div className="faq__bg2"></div> */}

      <div className="faq__container">
        <h2 className="faq__title title-h2">найчастіші запитання <span>+ відповіді</span></h2>

        <div className="faq__accourdings">
          {renderAccordion(data)}
        </div>
      </div>
    </section>
    
  )
}

export default Faq;