
import { useEffect, useState } from 'react';


import { Sling as Hamburger } from 'hamburger-react'

import instaIcon from '../../image/icon/insta.webp';
import telegramIcon from '../../image/icon/telegram.webp';
import youtubeIcon from '../../image/icon/youtube.webp';

import './Header.scss';

const Header = ({modal}) => {
  const [showMenu, setShowMenu] = useState(false);

  useEffect(() => {
    showMenu ? document.body.style.overflow = 'hidden' : document.body.style.overflow = ''
  }, [showMenu])

  const checkedMenu = () => {
    setShowMenu(false);
  }

  return (
    <>
      <header className='header desktop'>
        <a href="#" className="header__home">
          Юлія Баніт
        </a>
        <nav className='header__nav nav'>
          <ul className="nav__list">
            <li className="nav__item">
              <a href="#about" className="nav__link link__home">
                про курс
              </a>
            </li>
            <li className="nav__item">
              <a href="#program" className="nav__link">
                програма
              </a>
            </li>
            <li className="nav__item">
              <a href="#tariffs" className="nav__link">
                тарифи
              </a>
            </li>
            <li className="nav__item">
              <a href="#contact" className="nav__link">
                контакти
              </a>
            </li>
            <li className="nav__item">
              <a href="#reviews" className="nav__link">
                відгуки
              </a>
            </li>
          </ul>
        </nav>
        <ul className="header__social social">
          <li className="social__item">
            <a href="https://instagram.com/yulia_banit?igshid=OGQ5ZDc2ODk2ZA==" target="_blank" rel="noopener noreferrer">
              <img src={instaIcon} alt="insta" />
            </a>
          </li>
          <li className="social__item">
            <a href='https://t.me/banit_support' target="_blank" rel="noopener noreferrer">
              <img src={telegramIcon} alt="telegram" />
            </a>
          </li>
          <li className="social__item">
            <a href="https://youtube.com/@BanitYulia?si=05samznmvpgTjOKM" target="_blank" rel="noopener noreferrer">
              <img src={youtubeIcon} alt="youtube" />
            </a>
          </li>
        </ul>

        <div className="header__title">
          <h3>ОНЛАЙН</h3>
          <span>формат навчання</span>
        </div>
      </header>

      <header className='header header__mobile' style={showMenu ? {backgroundColor: '#1F1706'} : {backgroundColor: 'transparent'}}>
        <ul className="header__order header__order-mobile order__list">
          <li className="order__item">
            <a href="#" className="header__home" onClick={checkedMenu}> 
              Юлія Баніт
            </a>
          </li>
          <li className="order__item">
            <h3>ОНЛАЙН</h3>
            <span>формат навчання</span>
          </li>
          <li className='order__item'>
            <Hamburger toggled={showMenu} color={'#FFA719'} toggle={setShowMenu} />
          </li>
        </ul>

        {showMenu ? (
          <nav className='header__nav nav'>
            <ul className="nav__list">
              <li className="nav__item">
                <a href="#about" className="nav__link link__home" onClick={checkedMenu}>
                  про курс
                </a>
              </li>
              <li className="nav__item">
                <a href="#program" className="nav__link" onClick={checkedMenu}>
                  програма
                </a>
              </li>
              <li className="nav__item">
                <a href="#tariffs" className="nav__link" onClick={checkedMenu}>
                  тарифи
                </a>
              </li>
              <li className="nav__item">
                <a href="#contact" className="nav__link" onClick={checkedMenu}>
                  контакти
                </a>
              </li>
              <li className="nav__item">
                <a href="#reviews" className="nav__link" onClick={checkedMenu}>
                  відгуки
                </a>
              </li>
              <li>
                <ul className="header__social social">
                  <li className="social__item">
                    <a onClick={checkedMenu} href="https://instagram.com/yulia_banit?igshid=OGQ5ZDc2ODk2ZA==" target="_blank" rel="noopener noreferrer">
                      <img src={instaIcon} alt="insta" />
                    </a>
                  </li>
                  <li className="social__item">
                    <a onClick={checkedMenu} href="https://t.me/banit_support" target="_blank" rel="noopener noreferrer">
                      <img src={telegramIcon} alt="telegram" />
                    </a>
                  </li>
                  <li className="social__item">
                    <a onClick={checkedMenu} href="https://youtube.com/@BanitYulia?si=05samznmvpgTjOKM" target="_blank" rel="noopener noreferrer">
                      <img src={youtubeIcon} alt="youtube" />
                    </a>
                  </li>
                </ul>
              </li>
            </ul>
            
          </nav>
          )
          :
          null
        }   
          

        
      </header>
    </>
  )
}

export default Header;