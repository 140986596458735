import { useEffect, useState } from 'react';

import { Route, Routes } from 'react-router-dom';

// import Header from '../Header/Header';
// import Main from '../Main/Main';
// import StepOrder from '../StepOrder/StepOrder';
// import UniqueProposition from '../UniqueProposition/UniqueProposition';
// import OurServices from '../OurServices/OurServices';
// import Tariffs from '../Tariffs/Tariffs';
// import PriceIncluded from '../PriceIncluded/PriceIncluded';
// import WorkExemple from '../WorkExemple/WorkExemple';
// import Reasons from '../Reasons/Reasons';
// import Reviews from '../Reviews/Reviews';
// import Faq from '../Faq/Faq';
// import Modal from '../Modal/Modal';
// import Footer from '../Footer/Footer';

import MainPage from '../../pages/Main/Main';
import Thanks from '../../pages/Thanks/Thanks';


import './App.scss';

function App() {

  function reveal() {
    var reveals = document.querySelectorAll(".reveal");
  
    for (var i = 0; i < reveals.length; i++) {
      var windowHeight = window.innerHeight;
      var elementTop = reveals[i].getBoundingClientRect().top;
      var elementVisible = 150;
  
      if (elementTop < windowHeight - elementVisible) {
        reveals[i].classList.add("active");
      } else {
        reveals[i].classList.remove("active");
      }
    }
  }
  
  window.addEventListener("scroll", reveal);
  

  return (
    <>
      <Routes>
            <Route path="/" element={<MainPage />} />
            <Route path="thanks" element={<Thanks/>} />
            
            {/* <Route path="*" element={<NotFoundPage />} /> */}
          </Routes>
    </>
  );
}

export default App;
