import {Helmet} from "react-helmet";

import { Link } from 'react-router-dom';
import './Thanks.scss';

const Thanks = () => {
  return (
    <section className='thanks'>

      <Helmet>
          <meta charSet="utf-8" />
          <title>Дякую Вам</title>
      </Helmet>
      <h2 className='thanks__title'>Дякуємо за вибір!</h2>
      <p className='thanks__text'>Наш менеджер зв'яжеться з вами та проконсультує з будь-яких питань найближчим часом :)</p>
      <Link to={'/'} className='thanks__button button'>НА ГОЛОВНУ</Link>
    </section>
  )
  
}

export default Thanks;