import one from '../../image/for-whom/1.webp';
import two from '../../image/for-whom/2.webp';
import three from '../../image/for-whom/3.webp';
import four from '../../image/for-whom/4.webp';
import five from '../../image/for-whom/5.webp';
import six from '../../image/for-whom/6.webp';
import seven from '../../image/for-whom/7.webp';
import eight from '../../image/for-whom/8.webp';
import nine from '../../image/for-whom/9.webp';


import './ForWhom.scss';

const data = [
  {
    src: one,
    text: 'постійно економить та відчуває тривогу за власні фінанси',
    className: '',
  },
  {
    src: two,
    text: 'робить імпульсивні покупки та хоче позбутись цього',
    className: '',
  },
  {
    src: three,
    text: 'хоче навчитись закривати борги швидко та дешево',
    className: '',
  },
  {
    src: four,
    text: 'хоче навчитись накопичувати гроші та керувати власним бюджетом',
    className: 'small small-left',
  },
  {
    src: five,
    text: 'хоче навчитись правильно говорити з дітьми про гроші',
    className: 'small',
  },
  {
    src: six,
    text: 'хоче вийти на новий фінансовий рівень',
    className: 'small small-right',
  },
  {
    src: seven,
    text: 'хоче сформувати власну приватну пенсію',
    className: '',
  },
  {
    src: eight,
    text: 'хоче навчитись розпізнавати фінансові піраміди',
    className: '',
  },
  {
    src: nine,
    text: 'хоче навчитись примножувати власні фінанси',
    className: '',
  },
]

const ForWhom = () => {

  const renderItem = (data) => {
    return data.map((item, i) => {
      const {src, text, className} = item;

      return (
        <li className={`for-whom__item item ${className}`} key={text}>
          <div className="item__container">
            <span className='item__num'>{i + 1}</span>
            <img className='item__icon' src={src} alt="text" />
          </div>
          <p className='item__text'>{text}</p>
        </li>
      )
    })
  }

  return (
    <section className='for-whom'>
      <div className="for-whom__container">
        <h2 className='for-whom__title title-h2'>Цей курс для тих, хто:</h2>

        <ul className="for-whom__list">
          {renderItem(data)}
        </ul>
      </div>
    </section>
  )
}

export default ForWhom;